
const Education = () => {
    return (
		<div className="container-xxl" id="education">
			<div className="container py-5">

				<div className="row justify-content-center" data-aos="fade-up">
					<div className="col-xl-6 col-lg-7 col-md-9 text-center">
						<h2>
							Education
						</h2>
						<p>
							From eager learner to skilled specialist, embracing challenges and continuous growth for success.
						</p>
					</div>
				</div>

				<div className="row" data-aos="fade-up">
					<div className="col-md-12">
						<div className="education-timeline">
							
							<div className="timeline">
								<div className="row">
									<div className="col-6 info">
										<div className="row" style={{height: "100%"}}>
											<div className="col-md-7 year">
												<p>2014-2018</p>
											</div>

											<div className="col-md-5 logo">
												<img src="assets/images/logos/spiru_haret.png"/>
											</div>
										</div>
									</div>
									<div className="col-6 info">
										<h3 className="title">High School Degree</h3>
										<p className="description">
											<strong>Mathematics Intensive Computer Sciences</strong> - Multiple diplomas at the Prahova region level and <strong>qualified twice in a row for the national web development olympiad</strong>.
										</p>
									</div>
								</div>
							</div>

							<div className="timeline">
								<div className="row">
									<div className="col-6 info">
										<h3 className="title">University Bachelor Degree</h3>
										<p className="description">
											<strong>Computer Sciences</strong> - Successfully completing and supporting the bachelor's thesis titled <strong>"The Integration of Web Technologies for Enhancing Learning Skills"</strong>.
										</p>
									</div>

									<div className="col-6 info">
										<div className="row" style={{height: "100%"}}>

											<div className="col-md-5 logo">
												<img src="assets/images/logos/titu_maiorescu.png"/>
											</div>

											<div className="col-md-7 year">
												<p>2018-2021</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="timeline">
								<div className="row">
									<div className="col-6 info">
										<div className="row" style={{height: "100%"}}>
											<div className="col-md-7 year">
												<p>2021-2023</p>
											</div>

											<div className="col-md-5 logo">
												<img src="assets/images/logos/titu_maiorescu.png"/>
											</div>
										</div>
									</div>

									<div className="col-6 info">
										<h3 className="title">Master's Degree</h3>
										<p className="description">
											<strong>Cibersecurity</strong> - Successfully completing and supporting the master's thesis titled <strong>"Web vulnerabilities and security methods"</strong>.
										</p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Education