import { TypeAnimation } from 'react-type-animation';
import { Parallax } from 'react-parallax';

const Home = () => {
    return (
       
        <div className="container-xxl" id="home">
            <Parallax bgImage={"assets/images/backgrounds/home.jpg"} strength={250} blur={{ min: -15, max: 15 }}>
                <div className="container">

                    <div className="home bg-blur">
                        <div className="wave">
                            <img src="assets/images/wave_home.png"/>
                        </div>

                        <div className="content">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-7">
                                        <div className="text-center">
                                            <h2>
                                                I'm Marian Act
                                            </h2>

                                            <h4>
                                                I'm an <TypeAnimation style={{color: '#27E278'}}
                                                    sequence={[
                                                        'Web Developer',
                                                        1000,
                                                        'Security Engineer',
                                                        1000,
                                                        'Software Developer',
                                                        1000
                                                    ]}
                                                    speed={50}
                                                    repeat={Infinity}
                                                />
                                            </h4>
                                            
                                            <p data-aos="fade-up">
                                                Ready to drive innovation and deliver exceptional results. Highly skilled IT professional with a proven track record in solving complex challenges and team collaboration.
                                            </p>
                                            <a className="btn btn-default rounded-0" href="CV_ANCUTA_MARIAN.pdf" target="_blank" rel="noopener noreferrer" data-aos="fade-up">
                                                Download CV
                                            </a>
                                            <a className="btn btn-outline-white rounded-0" href="https://ro.linkedin.com/in/marian-daniel-ancuta" target="_blank" rel="noopener noreferrer" data-aos="fade-up">
                                                Get In Touch
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            
            </Parallax>
        </div>
    );
}

export default Home