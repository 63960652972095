import { AppProvider } from './context/AppContext.js';

import Header from './layouts/Header.js';
import Home from './layouts/Sections/Home.js';
import About from './layouts/Sections/About.js';
import Statistics from './layouts/Sections/Statistics.js';
import Education from './layouts/Sections/Education.js';
import Experience from './layouts/Sections/Experience.js'
import Footer from './layouts/Footer.js';
import Projects from './layouts/Sections/Projects.js';
import Testimonials from './layouts/Sections/Testimonials.js';
import Thanks from './layouts/Sections/Thanks.js';

function App() {
  	return (
		<AppProvider>
			<Header/>
			<Home/>
			<About/>
			<Experience/>
			<Statistics/>
			<Education/>
			<Projects/>
			<Testimonials/>
			<Thanks/>
			<Footer/>
    	</AppProvider>
	);
}

export default App;