import OwlCarousel from "react-owl-carousel";

const Testimonials = () => {
    return (
		<div className="container-xxl" id="testimonials">
			<div className="container py-5">

				<div className="row justify-content-center mb-5" data-aos="fade-up">
					<div className="col-xl-6 col-lg-7 col-md-9 text-center">
						<h2>
                            Testimonials
						</h2>
						<p>
                            Transforms challenges into success. Valued by many, my work is praised for dedication, expertise, and exceptional results
						</p>
					</div>
				</div>

				<div className="row" data-aos="fade-up">
                    <OwlCarousel className="owl-theme testimonial owl-carousel" autoplay={true} autoplayTimeout={10000} loop={true} margin={20} responsive={{"0": {"items": "1"}, "767": {"items": "2"}, "1199": {"items": "3"}}}>
                        <div className="item">
                            <div className="box">
                                <div className="user">
                                    <div className="avatar">
                                        <img src="assets/images/avatars/dacia_cristian_zavoianu.jpg"/>
                                    </div>
                                    <div className="info">
                                        <h6>
                                            Cristian Zavoianu
                                        </h6>
                                        <span>
                                            Software Developer
                                        </span>
                                    </div>
                                </div>
                                <div className="description">
                                    <p>
                                        I worked with Marian on several projects, and I would like to <strong>recommend Marian as a professional</strong>.

                                        He has strong knowledge of <strong>Python</strong> and he uses extensively <strong>Flask</strong>, <strong>Eel</strong>, <strong>SQLAlchemy</strong>, <strong>Selenium</strong>, <strong>Pandas</strong>, <strong>Numpy</strong>.
                                        He has also good skills of <strong>Docker</strong>, <strong>ReactJS</strong>, <strong>VBA</strong> and <strong>SQL</strong>.

                                        He offers robust and optimized solutions.
                                        During his projects, Marian used <strong>Confluence</strong> and <strong>Jira</strong>.

                                        Marian is <strong>fast learner</strong> and <strong>constantly improve his programming skills</strong>.
                                        He is a <strong>team player</strong> and is <strong>always ready to share his knowledge with the team</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="box">
                                <div className="user">
                                    <div className="avatar">
                                        <img src="assets/images/avatars/inetum_roxana_bunea.jpg"/>
                                    </div>
                                    <div className="info">
                                        <h6>
                                            Roxana Bunea
                                        </h6>
                                        <span>
                                            Business Analyst
                                        </span>
                                    </div>
                                </div>
                                <div className="description">
                                    <p>
                                        I want to say that Marian is a <strong>very dedicated person and passionate about what he does</strong>. 
                                        He is a <strong>true professional</strong>, and this is reflected in the relationships he creates with both clients and colleagues.
                                        He is <strong>very calculated and provides accurate estimates</strong>, which he always manages to <strong>complete on time</strong>. 
                                        Marian likes trying new things in order to <strong>solve every challenge</strong>, because he just enjoys these. 
                                        He's not only a <strong>fast learner</strong> but also he is a person who <strong>finds a solution for everything very quickly</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="box">
                                <div className="user">
                                    <div className="avatar">
                                        <img src="assets/images/avatars/dacia_octavian_turcu.jpg"/>
                                    </div>
                                    <div className="info">
                                        <h6>
                                            Octavian Turcu
                                        </h6>
                                        <span>
                                            Team Leader & Project Manager
                                        </span>
                                    </div>
                                </div>
                                <div className="description">
                                    <p>
                                        I am thrilled to recommend Marian, a remarkably <strong>talented developer</strong> with whom I've had the pleasure of collaborating at Renault Group. 
                                        Marian's blend of technical expertise in <strong>Python</strong>, <strong>SQL</strong>, and <strong>JavaScript</strong>, combined with <strong>exceptional creativity</strong> and <strong>soft skills</strong>, makes her an invaluable asset to any team.
                                        His <strong>collaborative approach</strong> makes him a <strong>favorite among team members</strong>.
                                        Marian's <strong>problem-solving skills are exceptional</strong>, often leading the way in brainstorming sessions and <strong>tackling challenges with a positive, can-do attitude.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="box">
                                <div className="user">
                                    <div className="avatar">
                                        <img src="assets/images/avatars/inetum_ana_verdis.jpg"/>
                                    </div>
                                    <div className="info">
                                        <h6>
                                            Ana Verdis
                                        </h6>
                                        <span>
                                            Business Analyst & Scrum Master
                                        </span>
                                    </div>
                                </div>
                                <div className="description">
                                    <p>
                                        I worked with Marian on a <strong>complex project for Corporate</strong>.
                                        Marian is a very <strong>smart person</strong>, <strong>fast learner</strong> and <strong>very sociable</strong>. 
                                        He is <strong>proactive</strong>, <strong>always coming with a solution</strong>.

                                        He is <strong>constantly improving and expanding his programming knowledge</strong> and also <strong>sharing with the development team</strong> from his experience, about implemented solution on other projects. <br/>
                                        He has experience working in agile projects, using <strong>Jira</strong> and <strong>Confluence</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="box">
                                <div className="user">
                                    <div className="avatar">
                                        <img src="assets/images/avatars/inetum_victor_neagu.jpg"/>
                                    </div>
                                    <div className="info">
                                        <h6>
                                            Victor Neagu
                                        </h6>
                                        <span>
                                            Business Analyst
                                        </span>
                                    </div>
                                </div>
                                <div className="description">
                                    <p>
                                        I had the <strong>pleasure to work with Marian</strong>.
                                        I am always impressed by the fact that, regardless of the complexity of the project, <strong>he understands the business need long before I write down the process procedure</strong>.
                                        Marian is <strong>very organized</strong> and I had the pleasure to learn from his way of organizing/thinking through a project which required SQL databases.
                                        Furthermore, he demonstrates his organizing abilities through the <strong>accurate project estimates</strong>.
                                        Marian is a <strong>self-learner</strong> and in order to <strong>provide the best solutions</strong> for the clients, <strong>he invests a lot of his personal time</strong> to learn new methods of <strong>providing quality</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
				</div>
			</div>
		</div>
    )
}

export default Testimonials