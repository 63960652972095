import { IconMail, IconBrandGithub, IconBrandLinkedin } from "@tabler/icons";

const Footer = () => {
    return (
        <div className="footer">
			<div className="wave">
				<img src="assets/images/wave_footer.png"/>
			</div>
            <div className="top">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 text-center">
							<div className="mr-auto">
								<a className="p-3 pl-0" href="mailto:ancuta.marian.daniel@gmail.com">
									<IconMail/>
								</a>
								<a className="p-3 pl-0" href="https://github.com/MarianAct" target="_blank" rel="noopener noreferrer">
									<IconBrandGithub/>
								</a>
								<a className="p-3 pl-0" href="https://ro.linkedin.com/in/marian-daniel-ancuta" target="_blank" rel="noopener noreferrer">
									<IconBrandLinkedin/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="container">
					<div className="row justify-content-center">
						<p className="m-0 text-center">
							Copyright © 2023 <a href="#">MarianAct</a>. All right reserved.
						</p>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Footer