import CountUp from 'react-countup';
import { Parallax } from 'react-parallax';

const Statistics = () => {
    return (
        <div className="container-xxl">
            <Parallax bgImage={"assets/images/backgrounds/statistics.jpg"} strength={-350}>
                <div className="container py-5">
                    <div className="statistics bg-blur">
                        <div className="row" data-aos="fade-down">
                            <div className="col-lg-3 col-md-3 col-6 mb-2 mt-2">
                                <div className="item text-center">
                                    <i className="flaticon-briefing"></i>
                                    <h3 className="info">
                                       <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={43}/>+
                                    </h3>
                                    <p>
                                        Projects
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-2 mt-2">
                                <div className="item text-center">
                                    <i className="flaticon-laugh"></i>
                                    <h3 className="info">
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={99}/>%
                                    </h3>
                                    <p>
                                        Projects On Time
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-2 mt-2">
                                <div className="item text-center">
                                    <i className="flaticon-coffee-cup"></i>
                                    <h3 className="info">
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={100}/>%
                                    </h3>
                                    <p>
                                        Customer Satisfaction
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-2 mt-2">
                                <div className="item text-center">
                                    <i className="flaticon-trophy"></i>
                                    <h3 className="info">
                                        <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={5}/>+
                                    </h3>
                                    <p>
                                        Certifications 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    )
}

export default Statistics