import OwlCarousel from "react-owl-carousel";

const Thanks = () => {
    return (
        <div className="container-xxl">
            <div className="container py-5">
                <OwlCarousel className="owl-theme thanks owl-carousel" autoplay={true} autoplayTimeout={3000} loop={true} margin={20} dots={false} responsive={{"0": {"items": "3"}, "767": {"items": "5"}, "1199": {"items": "7"}}}>
                    <div className="item">
                        <img src="assets/images/thanks/logo-1.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-2.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-3.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-4.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-5.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-6.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-7.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-8.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-9.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-10.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-11.svg" alt=""/>
                    </div>

                    <div className="item">
                        <img src="assets/images/thanks/logo-12.svg" alt=""/>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default Thanks