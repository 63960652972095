import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";

function Progressbar({style, name, value}) {

    const [progressViewRef, inView] = useInView();

    return(<>
        <div className="d-flex justify-content-between">
            <h6 className="font-weight-bold">{name}</h6>
            <h6 className="font-weight-bold">
                <CountUp enableScrollSpy={true} scrollSpyOnce={true} end={value} delay={0.6} />%
            </h6>
        </div>
        
        <div className="progress" ref={progressViewRef}>
            <motion.div
                className={"progress-bar " + style}
                animate={inView && {
                    width: `${value}%`
                }}
            />
        </div>
    </>);
}

const About = () => {

    return (
        <div className="container-xxl" id="about">
            <div className="container py-5">

                <div className="about">
                    <div className="row g-5">
                        <div className="col-lg-6" data-aos="fade-up">
                            <div className="d-flex align-items-center mb-5">
                                <div className="years flex-shrink-0 text-center me-4">
                                    <h1 className="display-1 mb-0">5</h1>
                                    <h5 className="mb-0">Years</h5>
                                </div>
                                <h3 className="lh-base mb-0">of working experience as a software developer</h3>
                            </div>
                            
                            <p className="mb-4">Experience the epitome of programming excellence with me! A collaborative team player, delivering top-quality results on time. My skills ensure productivity, precision, and successful project completion.</p>
                            <p className="mb-3"><i className="far fa-check-circle text-primary me-3"></i> Proactivity Teamwork</p>
                            <p className="mb-3"><i className="far fa-check-circle text-primary me-3"></i> Fast Learning</p>
                            <p className="mb-3"><i className="far fa-check-circle text-primary me-3"></i> High Quality Product</p>
                            <p className="mb-3"><i className="far fa-check-circle text-primary me-3"></i> On Time Project Delivery</p>
                        </div>

                        <div className="col-lg-6 mt-4" data-aos="fade-up">
                            
                            <div className="d-flex align-items-center mb-3">
                                <h4 className="me-3 mb-0">About me</h4>
                            </div>
                            <p className="mb-5">
                                Versatile programmer with expertise in languages such as Python, JavaScript, PHP and VBA. Skilled in Web Development, UX/UI Design, Cybersecurity and Software Architecture. Continuously learning and staying updated with industry trends.
                            </p>

                            <div className="row g-3 mb-4">
                                <div className="col-md-6">
                                    <div className="skill mb-4">
                                        <Progressbar style="bg-warning" name="Javascript" value={90}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-success" name="Python" value={95}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-dark" name="PHP" value={80}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-danger" name="VBA" value={95}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-primary" name="Docker" value={90}/>
                                    </div>
                                </div>
    
                                <div className="col-md-6">
                                    <div className="skill mb-4">
                                        <Progressbar style="bg-info" name="ReactJS" value={95}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-primary" name="Flask" value={95}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-info" name="CodeIgniter" value={85}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-warning" name="Linux" value={90}/>
                                    </div>

                                    <div className="skill mb-4">
                                        <Progressbar style="bg-dark" name="Cloud" value={85}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;

