import { useEffect, useRef } from "react"

import { Link } from "react-scroll";
import Sticky from 'react-stickynode'

import { IconMenu2, IconMail, IconPhone, IconBrandGithub, IconBrandLinkedin, IconX } from "@tabler/icons";

const handleToggleMenu = (action) => {
    const bodyClass = document.body.classList
    if (action !== undefined && action == 'close') {
        if (bodyClass.contains('offcanvas-menu')) {
            bodyClass.remove('offcanvas-menu')
        }
    } else {
        if (bodyClass.contains('offcanvas-menu')) {
            bodyClass.remove('offcanvas-menu')
        } else {
            bodyClass.add('offcanvas-menu')
        }
    }
}

const MenuList = () => {
    return (
        <>
            <li>
                <Link className="nav-link" to="home" spy={true} smooth={true} offset={-155} duration={500} onClick={() => {handleToggleMenu('close')}}>
                    Home
                </Link>
            </li>
            <li>
                <Link className="nav-link" to="about" spy={true} smooth={true} offset={-151} duration={500} onClick={() => {handleToggleMenu('close')}}>
                    About
                </Link>
            </li>
            <li>
                <Link className="nav-link" to="experience" spy={true} smooth={true} offset={-63} duration={500} onClick={() => {handleToggleMenu('close')}}>
                    Experience
                </Link>
            </li>
            <li>
                <Link className="nav-link" to="education" spy={true} smooth={true} offset={-56} duration={500} onClick={() => {handleToggleMenu('close')}}>
                    Education
                </Link>
            </li>
            
            <li>
                <Link className="nav-link" to="projects" spy={true} smooth={true} offset={-50} duration={500} onClick={() => {handleToggleMenu('close')}}>
                    Projects
                </Link>
            </li>
        </>
    )
}

const Header = () => {

    useEffect(() => {
        const handleCloseMenu = () => {
            if (window.innerWidth > 800) {
                handleToggleMenu('close')
            }
        }
        window.addEventListener("resize", handleCloseMenu);
        return () => window.removeEventListener("resize", handleCloseMenu);
    }, []);

    const refMenuRight = useRef(null);
    useEffect(() => {
        const handleClickOutsideMenu = (event) => {
            if (refMenuRight.current && !refMenuRight.current.contains(event.target)) {
                handleToggleMenu('close')
            }
        }
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () =>  document.removeEventListener("mousedown", handleClickOutsideMenu);
    }, [refMenuRight]);

    return (
        <>
            {/*------------------------------------------------------------------------------------------*/}
            <div ref={refMenuRight} className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3" onClick={() => {handleToggleMenu()}}>
                        <IconX/>
                    </div>
                </div>
                
                <div className="site-mobile-menu-body">
                    <ul className="site-nav-wrap">
                        <MenuList/>
                    </ul>
                </div>
            </div>
            {/*------------------------------------------------------------------------------------------*/}
            
            {/*------------------------------------------------------------------------------------------*/}
            <div className="site-navbar-wrap">
                <div className="site-navbar-top">
                    <div className="container py-3">
                        <div className="row align-items-center">

                            <div className="col-8">
                                <div className="d-flex mr-auto">
                                    <a className="d-flex align-items-center mr-4" href="mailto:ancuta.marian.daniel@gmail.com">
                                        <IconMail className="mr-2"/> <span className="d-none d-md-inline-block">ancuta.marian.daniel@gmail.com</span>
                                    </a>
                                    
                                </div>
                            </div>

                            <div className="col-4 text-right">
                                <div className="mr-auto">
                                    <a className="p-2 pl-0" href="https://github.com/MarianAct" target="_blank" rel="noopener noreferrer">
                                        <IconBrandGithub/>
                                    </a>
                                    
                                    <a className="p-2 pl-0" href="https://ro.linkedin.com/in/marian-daniel-ancuta" target="_blank" rel="noopener noreferrer">
                                        <IconBrandLinkedin/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Sticky enabled={true}>
                    <div className="site-navbar site-navbar-target">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-4">
                                    <h1 className="my-0 site-logo">
                                        <a href="index.html">Marian Act</a>
                                    </h1>
                                </div>

                                <div className="col-8">
                                    <nav className="site-navigation text-right" role="navigation">
                                        <div className="container">

                                            <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                                                <div className="site-menu-toggle text-white" onClick={() => {handleToggleMenu()}}>
                                                    <IconMenu2/>
                                                </div>
                                            </div>

                                            <ul className="site-menu main-menu d-none d-lg-block">
                                                <MenuList/>
                                            </ul>

                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
            </div>
            {/*------------------------------------------------------------------------------------------*/}
        </>
    )
}

export default Header