
const Experience = () => {
    return (
		<div className="container-xxl bg-gray" id="experience">
			<div className="container py-5">

				<div className="row justify-content-center mb-5" data-aos="fade-up">
					<div className="col-xl-6 col-lg-7 col-md-9 text-center">
						<h2>
							Experience
						</h2>
						<p>
							From eager learner to skilled specialist, embracing challenges and continuous growth for success.
						</p>
					</div>
				</div>

				<div className="row" data-aos="fade-up">
					<div className="col-md-12">
						<ul className="experience-timeline">
							<li>
								<div className="logo rounded-circle" style={{padding: "15px 12px 15px 15px"}}>
									<div className="background" style={{backgroundImage: "url('assets/images/logos/renault_group.svg')"}}></div>
								</div>
								<div className="description">
									<div>
										<h2>Renault Group</h2>
									</div>
									<div>
										<h4>Jan 2021 - Present </h4>
									</div>
									<div>
										<p className="text-muted" align="justify">
											I working for <strong>Renault Group Romania</strong> in the Digital Finance Factory team where I use <strong>Python</strong>, <strong>JavaScript</strong>, <strong>VBA</strong>, <strong>CSS3</strong>, <strong>HTML5</strong>.
											Related to <strong>Python</strong>, I master <strong>flask</strong>, <strong>flask-restx</strong>, <strong>sqlalchemy</strong>, <strong>pandas</strong>, <strong>numpy</strong>, <strong>cerberus</strong>, <strong>openpyxl</strong>, <strong>xlwings</strong>, <strong>pillow</strong>, <strong>pywin32</strong>, <strong>pyjwt</strong>, <strong>pyotp</strong>, <strong>eel</strong>, <strong>selenium</strong>.
											Related to <strong>JavaScript</strong> I have good skills of using <strong>NodeJS</strong>, <strong>ReactJS</strong>, <strong>Redux</strong>, <strong>ElectronJS</strong>.
											Inside my projects I use <strong>Docker</strong> technology, <strong>REST API</strong> architecture, and for storage I choose to use
											database between <strong>MySQL</strong>, <strong>SQLite</strong> or <strong>PostgreSQL</strong> based on application needs.
											I also know <strong>VBA</strong> for applications developed in MS Excel and MS Access.
											In team we use <strong>Agile</strong> methodology with scrum techniques. Each project require tools like <strong>Jira</strong> and <strong>Confluence</strong>.
										</p>
									</div>
								</div>
							</li>

							<li data-aos="fade-up">
								<div className="logo rounded-circle">
									<div className="background" style={{backgroundImage: "url('assets/images/logos/raiffeisen.svg')"}}></div>
								</div>
								<div className="description inverted">
									<div>
										<h2>Raiffeisen Bank</h2>
									</div>
									<div>
										<h4>Aug 2019 - Feb 2021</h4>
									</div>
									<div>
										<p className="text-muted" align="justify">
											My main activity was the <strong>analysis, development, implementation and administration</strong> of operational solutions specific to processes related to card transactions.
											In the course of the activity, I managed to <strong>rewrite and optimize</strong> a large part of the <strong>banking applications</strong> for <strong>automating internal IT flows</strong>, critical processes that required continuous good operation.
											Moreover, I participated in the <strong>development and maintenance</strong> of the banking system for <strong>confirming online payments with biometrics</strong> through the <strong>3D Secure</strong> process (Romania PSD2).
											In this position, I learned <strong>VBA</strong> and <strong>Python</strong>, I learned how banking IT flows work, the use of <strong>IBM AS400</strong> systems, the manipulation and <strong>maintenance of many databases with sensitive information of huge capacities</strong>.
										</p>
									</div>
								</div>
							</li>

							<li>
								<div className="logo rounded-circle">
									<div className="background" style={{backgroundImage: "url('assets/images/logos/euronet.svg')"}}></div>
								</div>
								<div className="description">
									<div>
										<h2>Euronet Worldwide</h2>
									</div>
									<div>
										<h4>Apr 2019 - Jul 2019</h4>
									</div>
									<div>
										<p className="text-muted" align="justify">
											My main activity was to administrate the systems and networks, within the <strong>Euronet</strong> company, the <strong>EEFT</strong> team, my assignment was the <strong>maintenance and development of IT infrastructure in Romania</strong>.
											Additionally, taking account that I had knowledge conserning <strong>PHP</strong>, <strong>MySQL</strong>, <strong>JavaScript</strong>, <strong>HTML5</strong> and <strong>CSS3</strong>, I concluded a development collaboration with the <strong>ePay team</strong>.
											In the course of the activity, <strong>I fixed numerous bugs in the application</strong> used at the time, later managing to <strong>develop from scratch an web application</strong> that incorporates all <strong>ePay invoicing automation</strong>.
										</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Experience