import { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import ModalVideo from 'react-modal-video';

const Projects = () => {

	const [activeModal, setActiveModal] = useState(0);

    return (
		<div className="container-xxl bg-gray" id="projects">
			<div className="container py-5">

				<ModalVideo
					channel="youtube"
					youtube={{ mute: 1, autoplay: 1 }}
					isOpen={activeModal == 1}
					videoId="1Dc72nBCxa4"
					onClose={() => setActiveModal(0)} 
				/>

				<ModalVideo
					channel="youtube"
					youtube={{ mute: 1, autoplay: 1 }}
					isOpen={activeModal == 2}
					videoId="1Dc72nBCxa4"
					onClose={() => setActiveModal(0)} 
				/>

				<ModalVideo
					channel="youtube"
					youtube={{ mute: 1, autoplay: 1 }}
					isOpen={activeModal == 3}
					videoId="SGbidHgWzdk"
					onClose={() => setActiveModal(0)} 
				/>

				<div className="row justify-content-center mb-5" data-aos="fade-up">
					<div className="col-xl-6 col-lg-7 col-md-9 text-center">
						<h2>
							Projects
						</h2>
						<p>
							From eager learner to skilled specialist, embracing challenges and continuous growth for success.
						</p>
					</div>
				</div>

				<div className="row justify-content-center mb-5" data-aos="fade-up">
					<OwlCarousel className="owl-theme projects owl-carousel" autoplay={true} autoplayTimeout={500000} loop={false} rewind={true} items={2} startPosition={1} margin={20} responsive={{"0": {"items": "1"}, "767": {"items": "2"}, "1199": {"items": "3"}}}>
						<div className="item" onClick={() => setActiveModal(1)}>
							<div className="card project-card background text-white" style={{ backgroundImage: `url("https://source.unsplash.com/600x900/?snow,window")` }}>
								<div className="card-img-overlay d-flex flex-column">
									<div className="card-body">
										<small className="card-meta mb-2">Desktop Application</small>
										<h4 className="card-title mt-0">
											<a className="text-white">
												Invest Analysis Synthesis
											</a>
										</h4>
										<small>
											<i className="far fa-clock"></i> May 10, 2023
										</small>
									</div>

									<div className="card-footer">
										<h6 className="my-1 text-white d-block">Using technologies</h6>
										<div style={{fontWeight: "500", fontSize: "85%"}}>
											JavaScript, ReactJS, ElectronJS, Python, Flask, SQLite, API
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="item" onClick={() => setActiveModal(2)}>
							<div className="card project-card background text-white" style={{ backgroundImage: `url("https://source.unsplash.com/600x900/?it,bird")` }}>
								<div className="card-img-overlay d-flex flex-column">
									<div className="card-body">
										<small className="card-meta mb-2">Web Application</small>
										<h4 className="card-title mt-0">
											<a className="text-white">
												Library 3D
											</a>
										</h4>
										<small>
											<i className="far fa-clock"></i> October 15, 2020
										</small>
									</div>

									<div className="card-footer">
										<h6 className="my-1 text-white d-block">Using technologies</h6>
										<div style={{fontWeight: "500", fontSize: "85%"}}>
											PHP, Codeigniter 4, JavaScript, MySQL, Bootstrap, CSS, HTML
										</div>
									</div>
								</div>
							</div>
						</div>
		
						<div className="item" onClick={() => setActiveModal(3)}>
							<div className="card project-card background text-white" style={{ backgroundImage: `url("https://i.imgur.com/mwBAkf3.png")` }}>
								<div className="card-img-overlay d-flex flex-column">
									<div className="card-body">
										<small className="card-meta mb-2">Web Application</small>
										<h4 className="card-title mt-0">
											<a className="text-white">
												SafeBank
											</a>
										</h4>
										<small>
											<i className="far fa-clock"></i> June 15, 2023
										</small>
									</div>

									<div className="card-footer">
										<h6 className="my-1 text-white d-block">Using technologies</h6>
										<div style={{fontWeight: "500", fontSize: "85%"}}>
											JavaScript, ReactJS, Python, Flask, MySQL, API, Docker, Cloud
										</div>
									</div>
								</div>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</div>
		</div>
    )
}

export default Projects