import { createContext, useState, useEffect } from "react";

const AppContext = createContext()

import Aos from "aos";
import "aos/dist/aos.css"

export const AppProvider = ({children}) => {

    const [isError, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)
    
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return <AppContext.Provider value={{
        isError,
        isLoading
    }}>
        {children}
    </AppContext.Provider>
}

export default AppContext